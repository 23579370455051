.RainbowButton {
  width: 7rem;
  height: 2.5rem;
  padding: 0.125rem;
  background-image: linear-gradient(
    90deg,
    #00c0ff 0%,
    #ffcf00 49%,
    #fc4f4f 80%,
    #00c0ff 100%
  );
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: bold;
  margin: 1.25rem 0;
}
.InputButton {
  content: attr(alt);
  width: 100%;
  height: 100%;
  background-color: #191919;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.RainbowButton:hover {
  animation: slidebg 2s linear infinite;
}

@keyframes slidebg {
  to {
    background-position: 20vw;
  }
}
