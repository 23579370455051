.RainbowButton {
  display: inline-flex; /* Se ajusta al tamaño del contenido */
  padding: 0.125rem;
  background-image: linear-gradient(
    90deg,
    #00c0ff 0%,
    #ffcf00 49%,
    #fc4f4f 80%,
    #00c0ff 100%
  );
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  font-weight: bold;
  margin: 1.25rem 0;
  min-width: 5rem; /* Evita que botones con poco texto sean demasiado pequeños */

}

.InputButton {
  width: auto; /* Se ajusta al contenido */
  min-width: 4rem; /* Evita que el botón sea demasiado pequeño */
  height: 100%;
  background-color: #191919;
  min-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0 1rem; /* Espaciado horizontal */
  border: none;
  text-transform: uppercase;
  white-space: nowrap; /* Evita que el texto se divida en varias líneas */
}

.RainbowButton:hover {
  animation: slidebg 2s linear infinite;
}

@keyframes slidebg {
  to {
    background-position: 20vw;
  }
}
