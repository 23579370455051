@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@400;700&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }
}

#root {
  margin: auto;
  min-width: 380px;
  background-color: #262626;
}

